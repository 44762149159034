<template>
  <div class="purchaseServicePlan-container">
    <a-page-header title="啟用健康服務">
      <template #tags>
        <QuestionCircleOutlined />
      </template>
    </a-page-header>
    <a-form :model="form" :label-col="labelCol" :wrapper-col="wrapperCol">
      <a-form-item label="個案姓名">
        <span>{{ custInfo.name }}</span>
      </a-form-item>
      <a-form-item label="現有方案">
        <span>{{ custInfo.plan_now }}</span>
      </a-form-item>
      <a-form-item label="到期日期">
        <span>{{ custInfo.plan_data_end }}</span>
      </a-form-item>
      <a-form-item label="啟動碼">
        <a-input v-model:value="form.healthyCode" @change="checkCode">
          <template #suffix>
            <a-tooltip
              v-if="form.healthyCodeCheck"
              :title="form.healthyCodeMsg"
            >
              <CheckCircleOutlined style="color: #008000" />
            </a-tooltip>
            <a-tooltip
              v-else-if="
                !form.healthyCodeCheck && form.healthyCode.length == 10
              "
              :title="form.healthyCodeMsg"
            >
              <CloseCircleOutlined style="color: #ff0000" />
            </a-tooltip>
            <a-tooltip v-else title="請輸入驗證碼">
              <QuestionCircleOutlined style="color: #daa520" />
            </a-tooltip>
          </template>
        </a-input>
      </a-form-item>
      <a-form-item label="購買方案">
        <span>{{ form.healthyPlanName }}</span>
      </a-form-item>
      <a-form-item label="購買者">
        <span>{{ form.buyer }}</span>
      </a-form-item>
      <a-form-item label="開始日期">
        <a-date-picker
          v-model:value="form.startDate"
          :format="dateFormat"
          :allowClear="false"
          :default-value="today"
          :disabled-date="disabledDate"
        />
      </a-form-item>
      <!-- <a-form-item label="檢視者">
        <span>{{ form.viewerName }}</span>
        <a-button
          ghost
          type="primary"
          @click="setViewer"
          size="small"
          :style="{
            borderColor: '#0D88BC',
            color: '#0D88BC',
            marginLeft: '10px',
          }"
        >
          設定
        </a-button>
      </a-form-item> -->
      <a-form-item label="門市">
        <a-select v-model:value="form.store">
          <a-select-option
            v-for="item in userInfo.branches"
            :key="item.id"
            :value="item.id"
          >
            {{ item.name }}
          </a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item label="服務健管師">
        <a-input v-model:value="form.healthManager" disabled />
      </a-form-item>
    </a-form>
    <a-space>
      <a-button style="margin-left: 10px" @click="cancel">取消</a-button>
      <a-button
        type="primary"
        @click="save"
        :disabled="!form.healthyCodeCheck || form.store === ''"
      >
        儲存
      </a-button>
      <!-- :disabled="!form.healthyCodeCheck && form.store.length > 0" -->
    </a-space>
    <a-modal
      v-model:visible="setViewerVisible"
      :footer="null"
      width="80%"
      :bodyStyle="{ backgroundColor: '#f2f2f2' }"
    >
      <a-page-header title="購買服務方案"></a-page-header>
      <CustSearch :filter="setViewerFilter" @search="handelSearch" />
      <CustDataTable :searchResult="searchResult" @action1="handelAction1" />
    </a-modal>
  </div>
</template>

<script>
  import { onMounted, computed, ref, reactive } from 'vue'
  import { useRoute, useRouter } from 'vue-router'
  import { useStore } from 'vuex'
  import { notification } from 'ant-design-vue'
  import {
    QuestionCircleOutlined,
    CheckCircleOutlined,
    CloseCircleOutlined,
  } from '@ant-design/icons-vue'
  import moment from 'moment'

  import { buyPlan, checkHealthyCode } from '@/api/healthy'
  import CustSearch from '@/components/CustSearch'
  import CustDataTable from '@/components/CustDataTable'
  import { search } from '@/api/cust'
  export default {
    name: 'purchaseServicePlan',
    components: {
      QuestionCircleOutlined,
      CheckCircleOutlined,
      CloseCircleOutlined,
      CustSearch,
      CustDataTable,
    },
    setup() {
      const route = useRoute()
      const router = useRouter()
      const store = useStore()
      const today = moment(new Date().toISOString().slice(0, 10), 'YYYY/MM/DD')
      const custInfo = computed(() => store.getters['cust/custInfo'])
      const userInfo = computed(() => store.getters['user/userInfo'])
      const healthCases = computed(() => store.getters['healthy/healthCases'])
      const form = reactive({
        viewerName: custInfo.value.name,
        viewerCustId: custInfo.value.memberID,
        healthyPlanName: '',
        healthyPlanCode: '',
        healthyCode: '',
        healthyCodeCheck: false,
        healthyCodeMsg: '',
        buyer: '',
        startDate: new Date().toISOString().slice(0, 10).replaceAll('-', '/'),
        store: '',
        healthManager: '',
      })
      const setViewerVisible = ref(false)
      const setViewerFilter = reactive({
        searchItem: 'phone',
      })
      const searchResult = ref([])

      // 儲存button事件
      const save = async () => {
        const { data, status } = await buyPlan({
          programId: form.healthyPlanCode,
          caseCardId: custInfo.value.cardId,
          reviewerCardIds: [custInfo.value.cardId],
          locationId: form.store,
          healthcareManagerId: '',
          startTime: new Date(form.startDate).getTime() / 1000,
          activationCode: form.healthyCode,
          staffId: userInfo.value.account,
        })

        if (status === 200) {
          notification['success']({
            message: data.message,
          })
          router.go(-1)
        } else {
          notification['warning']({
            message: data.message,
          })
        }
      }

      // 取消事件
      const cancel = () => {
        router.go(-1)
      }

      // 修改檢視者事件
      const setViewer = () => {
        setViewerVisible.value = true
      }

      // 搜尋檢視者
      const handelSearch = (value) => {
        search(value).then((res) => {
          const data = res.data
          data.map((item) => {
            item.action1 = '選擇'
          })
          searchResult.value = data
        })
      }

      // 選擇檢視者
      const handelAction1 = (memberID) => {
        const selectCust = searchResult.value.filter((item) => {
          return item.memberID === memberID
        })
        form.viewerName = selectCust[0].name
        form.viewerCustId = selectCust[0].memberID
        setViewerVisible.value = false
      }

      // 檢查啟動碼
      const checkCode = async () => {
        if (form.healthyCode.length == 10) {
          const { data } = await checkHealthyCode({ code: form.healthyCode })
          // 啟動碼正確
          if (data.found) {
            // 未使用
            if (!data.isUsed) {
              form.healthyCodeCheck = true
              form.buyer = data.memberName
              form.healthyPlanName = data.programTitle
              form.healthyPlanCode = healthCases.value.find(
                (item) => item.barcode == data.barcode
              ).prod_id
              form.healthyCodeMsg = '驗證成功'
            } else {
              form.buyer = ''
              form.healthyPlanName = ''
              form.healthyPlanCode = ''
              form.healthyCodeCheck = false
              form.healthyCodeMsg = '此啟用碼已使用'
            }
          }
        } else {
          form.buyer = ''
          form.healthyPlanName = ''
          form.healthyPlanCode = ''
          form.healthyCodeCheck = false
          form.healthyCodeMsg = '啟用碼錯誤'
        }
      }

      const disabledDate = (current) => {
        return current < moment().subtract(1, 'days')
      }

      onMounted(async () => {
        await store.dispatch('cust/getCustInfo', {
          memberID: route.params.memberID,
        })
        await store.dispatch('healthy/getHealthCases')
      })

      return {
        custInfo,
        userInfo,
        storeList: computed(() => store.getters['store/storeList']),
        labelCol: { span: 4 },
        wrapperCol: { span: 14 },
        dateFormat: 'YYYY/MM/DD',
        today,
        form,
        save,
        cancel,
        setViewer,
        setViewerVisible,
        setViewerFilter,
        searchResult,
        handelSearch,
        handelAction1,
        checkCode,
        disabledDate,
      }
    },
  }
</script>
<style lang="less">
  .purchaseServicePlan-container {
    .ant-form {
      padding: 15px 0px;
      background-color: #fff;
      .ant-row {
        font-size: 20px;
      }
      label {
        font-size: 20px;
      }
    }
    .ant-space {
      float: right;
      margin: 12px;
    }
  }
  .ant-modal {
    .ant-page-header {
      padding: 0px;
    }
  }
</style>
